<template>
  <div>
    <Breadcrumbs page_title="home" :items="breadcrumbs" />

    <v-container class="mt-7">
      <v-row>
        <v-col cols="12" md="5">
          <v-card style="border-radius: 16px">
            <v-row>
              <v-col style="display: flex; justify-content: center">
                <div class="inner_">
                  <v-progress-circular
                    :rotate="-90"
                    :size="200"
                    :width="17"
                    :value="progressValue"
                    color="#4FB14E"
                    bg-color="primary"
                    style="cursor: pointer"
                    @click="$router.push('/course')"
                  >
                    <div class="outer">
                      <div style="color: #424242; font-size: 30px">
                        {{ "Seed" }}
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          white-space: nowrap;
                        "
                      >
                        <span style="color: #a6cc39; font-size: 24px">
                          {{ progressValue }}
                        </span>
                        <span
                          class="ml-1 mt-1"
                          style="color: #555555; font-size: 18px"
                          >{{ $t("Items") }}</span
                        >
                      </div>
                    </div>
                  </v-progress-circular>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-0 pt-0">
              <v-col
                style="display: flex; justify-content: center; padding-top: 5px"
              >
                <div>
                  <span style="color: #424242; font-size: 20px">{{
                    $t("Youhave")
                  }}</span>
                  <span
                    style="
                      color: #a6cc39;
                      font-size: 34px;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                    @click="$router.push('/event')"
                  >
                    {{ oneWeekEvent }}
                  </span>
                  <span style="color: #424242; font-size: 20px">{{
                    $t("Eventweek")
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <!-- events within 1 week under circle -->
            <v-row>
              <v-col>
                <v-list-item-group
                  v-for="item in eventItems"
                  :key="item.id"
                  class="list-style"
                >
                  <v-list-item
                    @click="
                      $router.push({
                        path: '/event/' + item.courseName.split('?').join(''),
                        query: {
                          course_id: item.id,
                          eventName: item.courseName,
                        },
                      })
                    "
                  >
                    <v-col cols="12" md="6" lg="6">
                      <v-img
                        :src="item.orgImage"
                        style="height: 160px; border-radius: 16px"
                      ></v-img>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <v-list-item-content>
                        <v-list-item-title
                          style="
                            font-size: 20px;
                            font-weight: bold;
                            color: #424242;
                          "
                          >{{ item.courseName }}</v-list-item-title
                        >

                        <v-list-item-subtitle
                          class="mt-2"
                          style="color: #4fb14e"
                        >
                          {{ $t("startdate") + ": " + item.startDate }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mt-2">
                          {{ $t("locationsm") + ": " + item.courseLocation }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mt-2">
                          {{ $t("time") + ": " + item.time }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-col>
                  </v-list-item>
                </v-list-item-group>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            style="border-radius: 16px; margin-top: 3rem; padding: 15px"
            v-show="bannerItems.length != 0"
          >
            <v-row class="mb-1">
              <v-col class="pl-7">
                <span
                  style="font-size: 22px; font-weight: bold; color: #424242"
                  >{{ $t("banner") }}</span
                >
              </v-col>
            </v-row>
            <div>
              <v-carousel v-model="selectedImage" hide-delimiters height="350">
                <v-carousel-item
                  v-for="(item, index) in bannerItems"
                  :key="index"
                >
                  <div class="carousel-image-wrapper">
                    <img
                      :src="item.orgImage"
                      alt="Image"
                      class="responsive-image"
                      @click="clickLink(item.linkURL, item.actionPage)"
                    />
                  </div>
                </v-carousel-item>
              </v-carousel>
              <!-- Delimiters (Indicators) -->
              <template>
                <div
                  id="carouselExampleIndicators"
                  class="carousel slide"
                  data-ride="carousel"
                >
                  <div class="carousel-inner">
                    <div
                      v-for="(item, index) in bannerItems"
                      :key="index"
                      :class="{ 'carousel-item': true, active: index === 0 }"
                    ></div>
                  </div>
                  <div class="carousel-indicators">
                    <span
                      v-for="(item, index) in bannerItems"
                      :key="index"
                      @click="selectedImage = index"
                      :class="{ active: selectedImage === index }"
                    ></span>
                  </div>
                </div>
              </template>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="7" class="pt-0">
          <div>
            <v-card
              v-show="requestedItems.length > 0"
              style="border-radius: 16px; padding: 10px"
            >
              <template>
                <v-container fluid class="py-0">
                  <v-row class="d-flex" no-gutters>
                    <v-col
                      v-for="item in paginatedGroups"
                      :key="item.id"
                      cols="12"
                      class="py-0"
                    >
                      <v-row
                        no-gutters
                        @click="goarticleDetail(item)"
                        :value="activeItem == item.id"
                        class="pt-3"
                      >
                        <v-col cols="12" sm="3">
                          <v-img :src="item.image" class="lessonimg"></v-img>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-list-item-content
                            style="color: #424242"
                            class="pt-1 pl-2"
                          >
                            <v-list-item-title
                              style="font-size: 18px; font-weight: bold"
                            >
                              {{ cutText(item.title) }}
                              <span
                                class="delaytext"
                                v-if="
                                  isDelayed(item.perDay) &&
                                  item.requestFlag == true
                                "
                                >({{ $t("delay") }})</span
                              >
                            </v-list-item-title>

                            <v-list-item-subtitle class="mt-3">
                              {{ item.decription }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="1"
                        >
                          <div
                            class="lessonIcon pr-1"
                            v-if="
                              item.contentCheck == 1 || item.contentCheck == 4
                            "
                          >
                            <v-img
                              src="@/assets/images/article_home.svg"
                              width="30"
                              height="30"
                              class="flex-none mt-1"
                              contain
                              style="cursor: pointer"
                            />
                          </div>
                          <div class="lessonIcon pr-1" v-else>
                            <v-progress-circular
                              :rotate="-90"
                              :size="35"
                              :width="3"
                              :value="item.progressTime * 100"
                              color="#4FB14E"
                            >
                              <v-icon
                                style="
                                  font-size: 22px;
                                  color: #cccccc;
                                  padding-left: 3px;
                                  padding-top: 0px;
                                "
                                >mdi-play-outline</v-icon
                              >
                            </v-progress-circular>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="d-flex justify-end align-center pr-1 pb-1"
                        >
                          {{ $t("enddate") + " : " + item.endDateOfContent }}
                        </v-col>
                      </v-row>
                      <div class="test">
                        <div class="border1"></div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <v-row
                no-gutters
                class="d-flex justify-space-between ml-2 mr-3 mt-1"
              >
                <v-col cols="auto" class="d-flex justify-start align-center">
                  <v-pagination
                    circle
                    v-model="page"
                    class="pagination"
                    :length="totalPages"
                  ></v-pagination>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="4"
                  class="d-flex justify-end align-center"
                >
                  <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="70"
                        v-bind="attrs"
                        v-on="on"
                        style="border: 1px solid #c0c0c0; opacity: 1"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="itemsPerPage = item.title"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <soundPlay
      ref="soundPlay"
      :show="showSound"
      @close="showSound = false"
      :audioName="audioName"
      @show_sound="show_sound"
      @updateProgressCircle="updateProgressCircle"
      :audioDescription="audioDescription"
      :dialog="dialog"
      :viewerFlag="viewerFlag"
      :audioFile="contentFile"
      :audioImg="audioImg"
      :contentid="contentId"
      :currentminutes="currentminutes"
      :memberID="memberID"
    ></soundPlay>
    <videoPlay
      ref="videoPlay"
      :show="showVideoDialog"
      @close="showVideoDialog = false"
      @updateProgressCircle="updateProgressCircle"
      :videoName="videoName"
      :videoPlayerDialog="videoPlayerDialog"
      :videoFile="video_File"
      :contentid="contentId"
      :courseID="courseID"
      :lastWatchedTime="lastWatchedTime"
      :viewerFlag="viewerFlag"
      :memberID="memberID"
      :video_page="1"
    ></videoPlay>

    <Announcement
      v-if="$store.getters.isLoggedIn"
      :AnnouncementItems="announcements"
      :show="announcementDialog"
      @close="announcementDialog = false"
    />
  </div>
</template>

<script>
import * as moment from "moment/moment";
import soundPlay from "../Library/_subLibrary/_path/_sound/soundPlay.vue";
import videoPlay from "../Library/_subLibrary/_path/_video/videoPlay.vue";
import Announcement from "../../components/Announcement.vue";

export default {
  components: {
    soundPlay,
    videoPlay,
    Announcement,
  },
  data() {
    return {
      selectedImage: 0,
      announcements: [],
      announcementDialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      paginationCount: [
        { title: 5 },
        { title: 10 },
        { title: 20 },
        { title: 30 },
      ],
      requestedItems: [], //requested cotents include library & lesson learn
      libraryItems: [],
      eventItems: [],
      bannerItems: [],
      oneWeekEvent: 0,
      interval: {},
      progressValue: 0,
      breadcrumbs: [
        // {
        //   sidebar_tilte: null
        // },
        {
          text: "home",
        },
      ],
      //video dialog data
      lastWatchedTime: "",
      videoDescription: "",
      videoName: "",
      //contentId: 0,
      video_File: "",
      //request_flag: false,
      courseID: 0,
      //...................
      videoPlayerDialog: false,
      showVideoDialog: false,
      //...end of video dialog data
      //Videodialog: false,
      //Videolink: "",
      //Sound Data
      showSound: false,
      //Addfav: false,
      audioDescription: "",
      audioName: "",
      audioImg: "",
      dialog: false,
      contentFile: "",
      contentId: 0,
      currentminutes: "",
      favFlag: "",
      maxlength: 35,
      bannermaxlength: 30,
      bannerDesLength: 60,
      activeItem: null,
      viewerFlag: false,
      memberID: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          value: "image",
          width: "10px",
        },
        {
          value: "title",
          width: "100px",
        },
        {
          value: "icon",
          width: "50px",
        },
      ];
    },
    paginatedGroups() {
      const startIndex = (this.page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.requestedItems.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.requestedItems.length / this.itemsPerPage);
    },
  },

  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.getAnnouncement();
    }
    this.startCarousel();
    this.getMobileBanner();
    this.getUserOneWeekEvent();
    this.getUserProgressCirleData();
    this.getRequestedLessonLearnContents();

    this.getFavourite();
    this.getNotificationCount();
    this.getEvent();
  },

  methods: {
    startCarousel() {
      setInterval(() => {
        this.selectedImage = (this.selectedImage + 1) % this.bannerItems.length;
      }, 6000); // 6 seconds
    },
    async getEvent() {
      let self = this;

      await self.$axios
        .get(
          `${self.web_url}Courses/GetAllCourse?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.oneWeekEvent = res.data.data.length;
          }
        });
    },
    async getAnnouncement() {
      let self = this;
      await self.$axios
        .get(
          `${self.web_url}Announcement/GetAnnouncementForUserView?comId=` +
            localStorage.getItem("companyID") +
            "&userId=" +
            parseInt(localStorage.getItem("UserID"))
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.announcements = res.data.data?.map((x) => ({
              ...x,
              showImage: true,
              doNotRepeat: false,
            }));
            if (self.announcements?.length == 0) {
              self.announcementDialog = false;
              self.$store.commit("setLoggedIn", false);
              self.loading = false;
            } else {
              self.announcementDialog = !self.Dialog;
              self.loading = false;
            }
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    },
    bannercutText(v) {
      if (v.length <= this.bannermaxlength) {
        return v;
      } else {
        return v.substring(0, this.bannermaxlength) + "...";
      }
    },
    bannerDescutText(v) {
      if (v.length <= this.bannerDesLength) {
        return v;
      } else {
        return v.substring(0, this.bannerDesLength) + "...";
      }
    },
    // isDelayed(date) {
    //   const perDay = date.split("T")[0];
    //   const currentDate = new Date().toISOString().split("T")[0];
    //   return currentDate > perDay;
    // },
    isDelayed(date) {
      if (date == null) {
        // console.error("Error in isDelayed: date is null or undefined");
        return false; // or handle appropriately
      }

      try {
        const perDay = date.split("T")[0];
        const currentDate = new Date().toISOString().split("T")[0];
        return currentDate > perDay;
      } catch (error) {
        console.error("Error in isDelayed:", error);
        return false; // or handle appropriately
      }
    },
    cutText(v) {
      if (v.length <= this.maxlength) {
        return v;
      } else {
        return v.substring(0, this.maxlength) + "...";
      }
    },
    updateProgressCircle(p) {
      this.requestedItems = this.requestedItems.map((x) => ({
        ...x,
        progressTime: x.id == p.id ? p.progressTime : x.progressTime,
      }));
      this.getRequestedLessonLearnContents();
    },
    clickLink(link, gopage) {
      if (gopage === "Course Menu") {
        this.$router.push("/course");
      } else if (gopage === "Event Menu") {
        gopage = this.$router.push("/event");
      } else if (gopage === "-") {
        return;
      }
      if (link === null || link === "") {
        return;
      }
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "http://" + link;
      }
      window.open(link, "_blank");
    },
    show_sound() {
      this.showSound = true;
    },
    async getMobileBanner() {
      let self = this;
      await this.$axios
        .get(
          `${this.web_url}Banner/GetBannerMobileUserView?CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.bannerItems = res.data.data.map((v, i) => ({
              ...v,
              index: i++,
            }));
          }
        });
    },

    async getRequestedLessonLearnContents() {
      await this.getRequestedLibraryContents();
      const res = await this.$axios.get(
        `${this.web_urlV6}CaseStudies/GetRequestLessonLearn?UserID=` +
          parseInt(localStorage.getItem("UserID")) +
          `&CompanyID=` +
          localStorage.getItem("companyID")
      );
      if (res.data.status == 0) {
        let temp = res.data.data.map((v) => ({
          ...v,
          title: v.lessonName,
          decription: v.wordDescription,
          image: v.picturebackground,
          contentCheck: 4,
          isComplete: false,
          createDate: moment(v.createDate),
          endDateOfContent: moment(v.endContentDate)
            .local()
            .format("DD MMM YYYY"),
        }));

        let combineArray = [...this.libraryItems, ...temp];
        this.requestedItems = combineArray.filter(
          (x) => x.requestFlag === true && x.isComplete === false
        );
        this.requestedItems.sort(function (a, b) {
          let key1 = b.createDate;
          let key2 = a.createDate;

          if (key1 < key2) {
            return -1;
          } else if (key1 == key2) {
            return 0;
          } else {
            return 1;
          }
        });
      }
    },

    async getRequestedLibraryContents() {
      let self = this;

      await this.$axios
        .get(
          `${this.web_urlV5}Contents/GetAllContents?UserID=` +
            parseInt(localStorage.getItem("UserID")) +
            `&CompanyId=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.libraryItems = res.data.data.map((v) => ({
              ...v,
              title: v.content_Name,
              decription: v.wordDescription,
              image: v.pictureBackground,
              createDate: moment(v.createDate),
              endDateOfContent: moment(v.endContentDate)
                .local()
                .format("DD MMM YYYY"),
            }));
          }
        });
    },

    async getUserOneWeekEvent() {
      let self = this;

      await self.$axios
        .get(
          `${self.web_urlV6}Courses/GetOneWeekEvent?UserID=` +
            parseInt(localStorage.getItem("UserID")) +
            `&CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (res) {
          if (res.data.status == 0) {
            self.eventItems = res.data.data.map((v) => ({
              ...v,
              time: moment(v.courseTime).local().format("LT"),
              startDate: moment(v.courseTime).local().format("DD/MM/YYYY"),
            }));
          }
        });
    },

    async getUserProgressCirleData() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };

      await self.$axios
        .post(`${self.web_urlV4}Contents/GetUserProgressMobileFrist`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            let notStarted =
              res.data.data.notStarted != null
                ? res.data.data.notStarted.length
                : 0;
            let inProgress =
              res.data.data.inProgress != null
                ? res.data.data.inProgress.length
                : 0;
            let delay =
              res.data.data.delay != null ? res.data.data.delay.length : 0;
            self.progressValue = notStarted + inProgress + delay;
          }
        });
    },

    goarticleDetail(item) {
      if (item.contentCheck === 1) {
        this.activeItem = item.id;
        this.$router.push({
          path: "/Library/_subLibrary/_path/_article/articleView",
          query: { article_id: item.id },
        });
      } else if (item.contentCheck === 2) {
        this.activeItem = item.id;
        this.showSound = true;
        this.audioImg = item.pictureBackground;
        this.audioName = item.content_Name;
        this.audioDescription = item.wordDescription;
        this.contentId = item.content_ID;
        this.dialog = true;
        this.contentFile = item.content_File;
        this.currentminutes = item.currentminutes;
        this.viewerFlag = item.viewerFlag;
        this.$refs.soundPlay.getFavorite(item.favFlag);
        this.$refs.soundPlay.GetCommentsForSound(item.content_ID);
      } else if (item.contentCheck === 4) {
        this.activeItem = item.id;
        this.$router.push({
          path: "/LessonLearn/" + item.lessonName.split("?").join(""),
          query: { lessonlearn_id: item.id },
        });
      } else {
        this.activeItem = item.id;
        this.showVideoDialog = true;
        this.video_File = item.content_File;
        this.contentId = item.content_ID;
        this.videoName = item.content_Name;
        this.courseID = -1; //distinguish from course
        this.lastWatchedTime = item.currentminutes;
        this.viewerFlag = item.viewerFlag;
        this.memberID = item.memberID;
        this.$refs.videoPlay.GetCommentsForVideo(item.content_ID);
        this.$refs.videoPlay.getFavorite(item.favFlag);
      }
    },
    closeDialog() {
      this.showVideoDialog = false;
      this.$refs.videoPlayer.pause();
    },
  },
};
</script>

<style scoped>
::v-deep .custom .v-carousel__controls__item.v-btn.v-btn--active {
  color: #a6cc39;
}
.lessonimg {
  width: 192px !important;
  height: 100px !important;
  border-radius: 12px;
}
.test {
  display: flex;
  justify-content: center;
}
.border1 {
  height: 1px;
  width: 100%;
  background-color: #00000027;
}
::v-deep .flex-none {
  flex: 0 0 auto !important;
}
.lessonIcon {
  color: #4fb14e;
  display: flex;
  justify-content: end;
  padding-top: 4px;
}
::v-deep .th,
td.img-col {
  width: 80px;
  overflow: hidden;
  background-color: unset;
}

::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  /* opacity: 1; */
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: top;
  padding: 9px;
  /* max-width: 200px; */
}

.library_img {
  width: 800px !important;
  /* margin: 0px 12px; */
  border-radius: 16px;
}

::v-deep .v-progress-circular__info {
  vertical-align: middle !important;
  display: unset !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.outer {
  height: 138px;
  width: 138px;
  padding: 29px;
  border-radius: 50%;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    6px 6px 10px -1px rgba(255, 255, 255, 1.7),
    -6px -6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 1.7);
}
.inner_ {
  height: 230px;
  width: 230px;
  padding: 15px;
  border-radius: 50%;
  box-shadow: inset -4px -4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset 4px 4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1), 0.5px 0.5px 0px rgba(0, 0, 0, 0.2),
    0px 12px 10px -10px rgba(0, 0, 0, 0.05);
}
@media (min-width: 1264px) {
  .container {
    max-width: unset; /*1185px;*/
  }
}

@media (min-width: 960px) {
  .container {
    max-width: unset; /* 900px; */
  }
}
::v-deep .theme--light.v-data-table > .v-data-table__wrapper :hover {
  background-color: transparent !important;
  cursor: pointer !important;
}
</style>

<style scoped>
.login-phone {
  color: #fff;
  font-size: 16px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.login-phone:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover {
  transition: 0.25s;
}
.login-phone:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
/* Delay Text */
.delaytext {
  color: #ff6060;
  font-size: 20px !important;
}

.carousel-image-wrapper {
  display: flex;
  border-radius: 16px;
  height: 100%;
}

.responsive-image {
  max-width: 100%;
  max-height: 100vh;
  border-radius: 16px;
  object-fit: fill;
}

.carousel-indicators {
  text-align: center;
  margin-top: 15px;
}

.carousel-indicators span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #c0c0c0; /* Inactive indicator color */
  margin: 0 10px;
  cursor: pointer;
}

.carousel-indicators span.active {
  background: #a6cc39; /* Active indicator color */
}
@media screen and (min-width: 1800px) {
  .carousel-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .responsive-image {
    max-width: 1000px; /* Adjust this value based on your desired maximum width */
    max-height: 100%; /* Take up available height without exceeding the specified max-height */
    border-radius: 16px;
    object-fit: contain; /* Use 'contain' instead of 'cover' to maintain the original aspect ratio */
    width: 100%;
  }
}
@media screen and (max-width: 959px) {
  .carousel-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
